import React, { useState, useEffect } from "react"
import Dashboard from "../../../components/Dashboard"
import { useGetPpoeSubscriptionsQuery } from "./subscriptionApiSlice"
import { formatDate } from "../../../helpers"
import { Card, Row, Col, Form, Button } from "react-bootstrap"
import MainGrid from "../../../components/MainGrid"
import { notify } from "../../../helpers"
import { useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { apiSlice } from "../../../app/api/apiSlice"

const columns = [
  { name: "count", description: "#" },
  { name: "first_name", description: "Firstname" },
  { name: "last_name", description: "Lastname" },
  { name: "phone_number", description: "Phone" },
  { name: "status", description: "Status" },
  { name: "account_number", description: "Account Number" },
  { name: "used_time", description: "Used Time" },
  { name: "downloaded_data", description: "Downloaded Data" },
  { name: "uploaded_data", description: "Uploaded Data" },
  { name: "last_seen", description: "Last Seen" },
  { name: "expires_at", description: "Expires At" },
  { name: "activated_at", description: "Activated At" },
]

// Custom debounce hook
function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);
  
  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    
    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);
  
  return debouncedValue;
}


const PPPSubscriptions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [currentPage, setCurrentPage] = useState(1)
  const [status, setStatus] = useState("")
  const [customKey, setCustomKey] = useState("account_number")
  const [customValue, setCustomValue] = useState("")

  const debouncedValue = useDebounce(customValue, 500)
  const {
    data,
    error: errors,
    isLoading,
    refetch,
  } = useGetPpoeSubscriptionsQuery({
    pageSize: 50,
    pageNumber: currentPage,
    status: status,
    [customKey]: debouncedValue
  })

  //   handlers
  const handlePageChange = (page) => {
    setCurrentPage(page)
  }

  const onRefresh = () => {
    refetch()
    notify("Success", false)
  }

  const onClear = () => {
    setStatus("")
    setCustomKey('account_number')
    setCustomValue("")
  }

  const handleViewProfile = (row) => {
    navigate(`/isp/ppp/customer/profile/${row.customer_uuid}`)
  }

  //   actions
  const actions = [
    { title: "View Customer Details", handler: handleViewProfile },
  ]

  // format data
  const formatedData = Array.isArray(data?.data)
    ? data?.data?.map((sub, index) => {
        return {
          ...sub,
          count: index + 1,
          expires_at: formatDate(sub.expires_at),
          activated_at: formatDate(sub.activated_at),
          last_seen: formatDate(sub.last_seen),
        }
      })
    : []

  const content = (
    <Card>
      <Card.Body>
        <MainGrid
          columns={columns}
          tableData={formatedData}
          isLoading={isLoading}
          error={errors}
          onPageChnage={handlePageChange}
          isSelectable={false}
          actions={actions}
          onRefresh={onRefresh}
          pagination={{
            currentPage: currentPage,
            pageSize: data?.page_size,
            totalItems: data?.total_count,
            next:
              currentPage < data?.total_pages
                ? currentPage + 1
                : data?.total_pages,
            previous: currentPage > 1 ? currentPage - 1 : 1,
            pagesTotal: data?.total_pages,
          }}
        />
      </Card.Body>
    </Card>
  )

  return (
    <Dashboard title={"PPPoe Subscriptions"}>
      <Row className="mb-4">
        <Col>
          <div className="tw-flex tw-items-center">
            <span className="tw-w-20 tw-font-semibold">Filter By</span>
            <div>
              <Form.Select
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <option value={""}>Select Status</option>
                <option value={"active"}>Active</option>
                <option value={"expired"}>Expired</option>
                <option value={"inactive"}>Inactive</option>
                <option value={"deactivated"}>Deactivated</option>
              </Form.Select>
            </div>
            <div className="tw-flex tw-ml-2">
              <Form.Select
                className="tw-w-48"
                value={customKey}
                onChange={(e) => setCustomKey(e.target.value)}
              >
                <option disabled={true}>Search By</option>
                <option value={"account_number"}>Account Number</option>
                <option value={"phone_number"}>Phone Number</option>
                <option value={"first_name"}>Firstname</option>
                <option value={"last_name"}>Lastname</option>
              </Form.Select>
              <Form.Control
                className="tw-mr-2 tw-w-60"
                type="text"
                name="customValue"
                value={customValue}
                onChange={(e) => setCustomValue(e.target.value)}
              />
              <Button onClick={onClear} variant="secondary">
                {" "}
                Clear{" "}
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>{content}</Col>
      </Row>
    </Dashboard>
  )
}

export default PPPSubscriptions
