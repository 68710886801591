import { apiSlice } from "../../../app/api/apiSlice"

const ppoeSubscriptionApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPpoeSubscriptions: builder.query({
      query: (params) => {
        let url = `/api/isp/ppoe/subscriptions`
        if (params.pageSize) {
          url = url + `?page_size=${params.pageSize}`
        }
        if (params.pageNumber) {
          url = url + `&page_number=${params.pageNumber}`
        }

        if (params.status) {
          url = url + `&status=${params.status}`
        }

        if (params.account_number) {
          url = url + `&account_number=${params.account_number}`
        }

        if (params.phone_number) {
          url = url + `&phone_number=${params.phone_number}`
        }

        if (params.first_name) {
          url = url + `&first_name=${params.first_name}`
        }

        if (params.last_name) {
          url = url + `&last_name=${params.last_name}`
        }

        return {
          url,
        }
      },
      providesTags: ['PpoeSubscriptions']
    }),
  }),
})

export const { useGetPpoeSubscriptionsQuery } = ppoeSubscriptionApiSlice
