import React, { useState, useEffect } from "react"
import * as Yup from "yup"
import { useFormik } from "formik"
import Dashboard from "../../../components/Dashboard"
import { 
  useGetRoutersQuery, 
  useCreateRouterMutation, 
  useUpdateRouterMutation,
  useDeleteRouterMutation
} from "./routerApiSlice"
import { Col, Row, Card, Button } from "react-bootstrap"
import MainGrid from "../../../components/MainGrid"
import RouterForm from "./RouterForm"
import { notify } from "../../../helpers"

const columns = [
  { name: "count", description: "#" },
  { name: "router_id", description: "Router ID" },
  { name: "status", description: "Router Status" },
  { name: "nasname", description: "Router IP Address" },
  { name: "type", description: "Router Type" },
  { name: "server", description: "Radius Server" },
  { name: "description", description: "Description" },
  { name: "company_id", description: "Isp Id" }
]

const Routers = () => {
  // state
  const {
    data,
    error: fetchRoutersErrors,
    isLoading: isFetching,
    refetch
  } = useGetRoutersQuery()

  const [createRouter, { isLoading: isCreating }] = useCreateRouterMutation()
  const [updateRouter, { isLoading: isEditing }] = useUpdateRouterMutation()
  const [deleteRouter, { isLoading: isDeleting }] = useDeleteRouterMutation()

  const [selected, setSelected] = useState(null)
  const [show, setShow] = useState(false)
  const [action, setAction] = useState("add")
  const [title, setTitle] = useState("Add Router")
  const [formErrors, setFormErrors] = useState(null)
  const [initialValues, setInitialValues] = useState({
    nasname: "",
    description: "",
    server: "",
    type: "",
    shortname: "",
    secret: ""
  })

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit(values, { resetForm }) {
      saveAndCLose(values, resetForm)
    },
  })

  const onRefresh = () => {
    refetch()
    notify("Success", false)
  }

  // handlers
  const handleShowModalToAdd = () => {
    
    setShow(true)
    setAction("add")
    setTitle("Add Router")
    setFormErrors(null)
  }


  const handleCloseDialog = () => {
    setShow(false)
    formik.resetForm()
  }

  const addRouter = async (values, resetForm)=> {
    try {
      await createRouter(values).unwrap()
        setShow(false)
        setFormErrors(null)
        refetch()
        notify('Router added Successfully', false)
        resetForm()
    } catch (error) {
      setFormErrors(error.data)
        notify('Failed. Please try again', true)
    }
  }

  const editRouter = async (values, resetForm)=> {
    try {
      await updateRouter({...values, id: selected.id}).unwrap()
        setShow(false)
        setFormErrors(null)
        refetch()
        notify('Router updated Successfully', false)
        resetForm()
    } catch (error) {
      setFormErrors(error.data)
        notify('Failed. Please try again', true)
    }
  }

  const saveAndCLose = async (values, resetForm) => {
    if(action === 'add') {
      await addRouter(values, resetForm);
    }else {
      await editRouter(values, resetForm);
    }
  }


  const handleDelete = async (row) => {
    try {
      await deleteRouter(row.id).unwrap()
        setShow(false)
        refetch()
        notify('Router Deleted Successfully', false)
    } catch (error) {

        notify('Failed. Please try again', true)
    }
  }

  const handleEdit = (row) => {
    setShow(true)
    setAction("edit")
    setTitle("Edit Router")
    setFormErrors(null)
    const values  = {
      nasname:  row?.nasname,
      description: row?.description,
      server: row?.server,
      type: row?.type,
      shortname: row?.shortname,
       secret: row?.secret
    }
    setInitialValues(values)
    setSelected(row)
    formik.setValues(values)

  }

  const actions = [
    {title: 'Edit', handler: handleEdit},
    {title: 'Delete', handler: handleDelete}
  ]



  // table format
  let count = 0
  const routerData = (data?.data || []).map((router) => {
    count++
    return {
      ...router,
      count,
    }
  })

  const content = (
    <Card>
      <Card.Body>
        <MainGrid
          columns={columns}
          tableData={routerData}
          isSelectable={false}
          isLoading={isFetching}
          error={fetchRoutersErrors}
          actions={actions}
          onRefresh={onRefresh}
        />
      </Card.Body>
    </Card>
  )

  return (
    <>
      <Dashboard title={"Routers"}>
        <Row>
          <Col>
            <div className="align-items-right btn-group">
              <div>
                <Button onClick={handleShowModalToAdd} variant="primary">
                  Add Router{" "}
                </Button>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>{content}</Col>
        </Row>
      </Dashboard>
      <RouterForm
        show={show}
        title={title}
        formik={formik}
        handleCloseDialog={handleCloseDialog}
        isLoading={isCreating || isEditing}
        errors={formErrors}
      />
    </>
  )
}

export default Routers

//validate ip ip
Yup.addMethod(Yup.string, 'ipv4', function() {
  return this.test('is-valid-ipv4', 'Please enter a valid IPv4 address', (value) => {
    if (!value) return true; // Allow empty values (use .required() to make it required)
    
    // Split the IP address by dots
    const octets = value.split('.');
    
    // A valid IPv4 address must have exactly 4 octets
    if (octets.length !== 4) {
      return false;
    }
    
    // Each octet must be a number between 0 and 255
    for (const octet of octets) {
      // Check if the octet is a valid number
      if (!/^\d+$/.test(octet)) {
        return false;
      }
      
      // Convert to number and check range
      const num = parseInt(octet, 10);
      
      // Check for leading zeros (except for 0 itself)
      if (octet.length > 1 && octet[0] === '0') {
        return false;
      }
      
      // Check if in valid range
      if (num < 0 || num > 255) {
        return false;
      }
    }
    
    return true;
  });
});



// validation
const validationSchema = Yup.object({
  nasname: Yup.string().ipv4().required("please enter valid ipv4 address"),
  description: Yup.string().required("description is required"),
  server: Yup.string().ipv4().required("radius server is required"),
  type: Yup.string().required("router type must be specified"),
  shortname: Yup.string().required("router type must be specified"),
  secret: Yup.string().required("router type must be specified")
})
