import React from "react"
import CustomDialog from "../../../components/CustomDialog"
import ErrorMessage from "../../../components/ErrorMessage"
import { Form, Col, Card, Row, Alert } from "react-bootstrap"

const RouterForm = ({
  show,
  title,
  handleCloseDialog,
  formik,
  isLoading,
  errors,
}) => {
  return (
    <CustomDialog
      show={show}
      title={title}
      onCLose={handleCloseDialog}
      onSave={formik.handleSubmit}
      isLoading={isLoading}
      size={"lg"}
    >
      <Form method="post" onSubmit={formik.handleSubmit}>
        <Card>
          <Card.Body>
            <Row>
              <Col>
              <ErrorMessage errors={errors} />
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>IP Address</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="ip Address"
                    value={formik.values.nasname}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="nasname"
                  />
                  {formik.errors.nasname && formik.touched.nasname && (
                    <Form.Text className="text-danger">
                      {formik.errors.nasname}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Router Naame"
                    value={formik.values.shortname}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="shortname"
                  />
                  {formik.errors.shortname && formik.touched.shortname && (
                    <Form.Text className="text-danger">
                      {formik.errors.shortname}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Radius Server</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="radius server"
                    value={formik.values.server}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="server"
                  />
                  {formik.errors.server &&
                    formik.touched.server && (
                      <Form.Text className="text-danger">
                        {formik.errors.server}
                      </Form.Text>
                    )}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Router Type</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="router type"
                    value={formik.values.type}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="type"
                  />
                  {formik.errors.type && formik.touched.type && (
                    <Form.Text className="text-danger">
                      {formik.errors.type}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="description"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="description"
                  />
                  {formik.errors.description && formik.touched.description && (
                    <Form.Text className="text-danger">
                      {formik.errors.description}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Secret</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="secret"
                    value={formik.values.secret}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="secret"
                  />
                  {formik.errors.secret && formik.touched.secret && (
                    <Form.Text className="text-danger">
                      {formik.errors.secret}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Form>
    </CustomDialog>
  )
}

export default RouterForm
